<script lang="ts" setup>
const props = defineProps({
  links: {
    type: Array as PropType<{ text: string; to: string }[]>,
    required: true
  },
  activeLink: {
    type: String,
    required: true,
    default: ''
  },
  darkMode: {
    type: Boolean,
    default: false
  },
  removeContainer: {
    type: Boolean,
    default: false
  }
})
function isActive(to: string): boolean {
  if (to === '/') {
    return to === props.activeLink
  }
  return props.activeLink.includes(to)
}
</script>
<template>
  <div
    class="z-40 flex h-[5rem] flex-row items-center justify-between py-[1.25rem]"
    :class="{ 'container ': !removeContainer }"
  >
    <div class="flex flex-row items-center gap-[5rem]">
      <slot name="company-logo"></slot>
      <div
        class="text-teal-600 hidden flex-row items-center gap-[1.25rem] text-t6 font-light lg:flex"
      >
        <div v-for="link of props.links" :key="link.to">
          <NuxtLinkLocale
            :to="link.to"
            class="hover:underline"
            :class="{
              'font-medium': isActive(link.to),
              'text-white': darkMode
            }"
          >
            {{ link.text }}
          </NuxtLinkLocale>
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center gap-[1.25rem] lg:flex">
      <slot name="menu"> </slot>
    </div>
  </div>
</template>
